footer {
	width: 100%;
	position: relative;
	z-index: 1;
	background-color: #f4f4f4;
}

.ftr-wrap {
	border-top: 1px solid #dcdcdc;
	padding: 70px 0 5px;
	@include respond-to('large') {
		padding: 45px 0 5px;
	}
	@include respond-to('small') {
		padding-top: 0px;
	}
	&__right {
		float: $right;
		width: 25%;
		@include respond-to('small') {
			width: 100%;
			margin-bottom: 20px;
		}
	}
	&__left {
		float: $left;
		width: 75%;
		padding-#{$right}: 40px;
		@include respond-to('small') {
			width: 100%;
			padding-#{$right}: 0px;
		}
	}
	&__bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
		// position: relative;
		padding: 25px 0 10px;
		@include respond-to('small') {
			justify-content: center;
			flex-flow: row wrap;
		}
		@include respond-to('extra-small') {
			padding-top: 10px;
		}
	}
}

.ftr-wrap-nav {
	font-size: 0;
	&__item {
		display: inline-block;
		width: 33.33%;
		font-size: initial;
		@include respond-to('extra-small') {
			width: 50%;
		}
	}
}

.ftr-logo {
	position: relative;
	text-align: center;
	@include respond-to('small') {
		width: 100%;
	}
	& img {
		max-width: 92px;
	}
}

.ftr-info {
	position: relative;
	font-size: 16px;
	font-weight: 400;
	color: #000;
	// padding-#{$left}: 180px;
	@include respond-to('small') {
		// text-align: center;
		// padding: 0;
		width: 100%;
		text-align: center;
	}
	@include respond-to('extra-small') {
		font-size: 16px;
	}
	& p {}
}

.site-footer__credit-holder {
	// position: absolute;
	// left: 0;
	// top: 60%;
	// transform: translateY(-50%);
	@include respond-to('small') {
		position: static;
		margin-top: 10px;
		width: 100%;
		text-align: center;
		// transform: translateY(0);
	}
}