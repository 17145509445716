.wrapper-banners {
	position: relative;
	width: 100%;
	height: 800px;
	overflow: hidden;
	@include respond-to('huge') {
		height: 750px;
	}
	@include respond-to('large') {
		height: 650px;
	}
	@include respond-to('medium') {
		height: 550px;
	}
	@include respond-to('small') {
		height: 400px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 800px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	z-index: 2;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 5;
		@include respond-to('small') {
			background-color: rgba(0, 0, 0, 0.5);
		}
	}
	@include respond-to('huge') {
		min-height: 750px;
	}
	@include respond-to('large') {
		min-height: 650px;
	}
	@include respond-to('medium') {
		min-height: 550px;
	}
	@include respond-to('small') {
		min-height: 400px;
	}
}

.banner-info {
	text-align: center;
	color: #fff;
	z-index: 100;
	position: absolute;
	top: 56%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 700px;
	width: 100%;
	padding: 0 20px;
	&__title {
		margin: 0 0 25px;
		font-size: 40px;
		font-weight: 400;
		color: #fff;
		line-height: 0.9;
		text-align: center;
		@include respond-to ('small') {
			font-size: 35px;
			margin: 0 0 15px;
		}
		@include respond-to ('extra-small') {
			font-size: 25px;
		}
		@include respond-to ('mobile-l') {
			font-size: 25px;
		}
		& span {
			display: block;
			font-size: 30px;
			font-weight: 700;
			color: #fff;
			line-height: 0.9;
			text-align: center;
			@include respond-to ('small') {
				font-size: 25px;
			}
			@include respond-to ('extra-small') {
				font-size: 20px;
			}
		}
	}
	&__text {
		max-width: 400px;
		width: 100%;
		margin: 0 auto;
		font-size: 18px;
		line-height: 1.2;
		font-weight: 400;
		@include respond-to ('mobile-l') {
			font-size: 17px;
			line-height: 1;
		}
	}
	&__btn {
		position: relative;
		display: inline-block;
		width: 150px;
		height: 60px;
		padding-top: 15px;
		margin: 25px auto 0;
		border: 1px solid #fff;
		font-size: 20px;
		font-weight: 700;
		background-color: transparent;
		text-decoration: none;
		color: #fff;
		text-align: center;
		&:focus {
			outline: none;
			text-decoration: none;
			color: #fff;
		}
		&:hover {
			text-decoration: none;
			color: #fff;
		}
		&:active {
			@include btn-effect-active;
		}
		@include respond-to('small') {
			margin: 15px auto 0;
			height: 45px;
			padding-top: 7px;
			font-size: 18px;
		}
	}
}