.horizontal-form {
	position: relative;
	z-index: 1;
	&.sec-padd {
		padding-bottom: 70px;
		@include respond-to('medium') {
			padding-bottom: 50px;
		}
		@include respond-to('small') {
			padding-bottom: 40px;
		}
		@include respond-to('extra-small') {
			padding-bottom: 30px;
		}
	}
}

.form-horizontal {
	& .contact-us-form {
		&__title {
			text-align: center;
		}
		&__text {
			font-size: 18px;
			font-weight: 400;
			color: #000;
			text-align: center;
			margin-bottom: 45px;
			@include respond-to('medium') {
				margin-bottom: 25px;
			}
		}
	}
	& .contact-wrap-input {
		// max-width: 1100px;
		width: 100%;
		margin: 0 auto;
		@include respond-to('larger') {
			font-size: 0;
			text-align: center;
		}
	}
	& .form-group {
		float: $right;
		vertical-align: top;
		margin: 0 0 0 20px;
		max-width: 290px;
		width: 100%;
		@include respond-to('larger') {
			// max-width: 260px;
			float: none;
			display: inline-block;
			margin: 0;
			padding: 0 8px;
			font-size: initial;
		}
		@include respond-to('1100') {
			// max-width: 23%;
		}
		@include respond-to('medium') {
			// max-width: 50%;
		}
		@include respond-to('extra-small') {
			padding: 0 4px;
			// max-width: 100%;
		}
		// &_check {
		// 	max-width: 108px;
		// 	@include respond-to('larger') {
		// 		max-width: 40%;
		// 		margin: 0;
		// 	}
		// 	@include respond-to('extra-small') {
		// 		margin-top: 2px;
		// 		max-width: 44%;
		// 	}
		// }
		&_ml {
			margin-#{$left}: 0;
		}
		&_button {
			max-width: 170px;
			float: $left;
			margin: 0;
			@include respond-to('larger') {
				display: block;
				margin: 10px auto 0;
				// max-width: 240px;
				width: 100%;
				float: none;
			}
		}
	}
	& .newslatter-input {
		height: 60px;
		width: 100%;
		padding: 11px 15px 10px;
		background-color: #fff;
		color: $text;
		font-weight: 400;
		font-size: 20px;
		border: none;
		// border-bottom: 1px solid #c5c5c5;
		border-radius: 0;
		box-shadow: none;
		@include respond-to('extra-small') {
			padding: 14px 10px 13px;
		}
		&::placeholder {
			font-size: 20px;
			color: $text;
			font-weight: 400;
		}
	}
	& .newslatter-btn {
		width: 100%;
		margin: 0;
		height: 60px;
		font-size: 18px;
		font-weight: 700;
		background-color: transparent;
		color: #000;
		border: 1px solid #000;
		&:active {
			@include btn-effect-active;
		}
	}
}

.help-block {
	font-size: 16px;
	color: #3c3a47 !important;
}

.form-control:focus {
	border-radius: 0;
}