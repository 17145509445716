.home-buttons {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	// box-shadow: 0 0 0 2px rgba($color: #262626, $alpha: 1.0);
	// border: 1px solid #000;
	&__overlay {
		background-color: rgba(0, 0, 0, .65);
		position: absolute;
		top: 0;
		#{$left}: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: .3s;
		&:hover {
			opacity: 1;
			.home-buttons__text {
				top: 50%;
				@include respond-to('large') {
					top: 40%;
				}
			}
		}
		@include respond-to('small') {
			// opacity: 1;
			// height: 33%;
			// top: 60%;
		}
	}
	&__text-wrap {
		position: relative;
		padding: 0 15px;
	}
	&__text {
		text-align: center;
		position: absolute;
		width: 100%;
		top: 45%;
		transform: translateY(-50%);
		transition: .5s;
		@include respond-to('small') {
			&::after {
				display: none !important;
			}
			&::before {
				display: none !important;
			}
		}
	}
	&__overlay-title {
		display: inline-block;
		padding-bottom: 20px;
		margin-bottom: 18px;
		border-bottom: 1px solid #fff;
		color: #fff;
		font-weight: 700;
		font-size: 30px;
		line-height: 0.9;
		@include respond-to('huge') {
			font-size: 25px;
		}
		@include respond-to('small') {
			font-size: 20px;
			// line-height: 20px;
		}
	}
	&__overlay-sub {
		color: $accent;
		font-size: 25px;
		font-weight: 400;
		line-height: 0.9;
		@include respond-to('huge') {
			font-size: 22px;
		}
		@include respond-to('small') {
			font-size: 18px;
			// line-height: 20px;
		}
	}
}

.btn-decor {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	&::after {
		width: 40px;
		height: 40px;
		vertical-align: middle;
		color: #fff;
		content: '';
		position: absolute;
		bottom: -80px;
		border: solid 1px #fff;
		left: 50%;
		transform: translateX(-50%);
		@include respond-to('larger') {
			bottom: -55px;
		}
	}
	&::before {
		content: '';
		width: 15px;
		height: 15px;
		border: 1px solid #fff;
		border-top-color: transparent;
		border-right-color: transparent;
		transform: rotate(45deg);
		position: relative;
		margin: 0 auto;
		display: block;
		bottom: -68px;
		left: 4px;
		@include respond-to('larger') {
			bottom: -43px;
		}
	}
}