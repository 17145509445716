.wrapper-all-content {
	background-color: #fff;
	position: relative;
	width: 100%;
	background-image: radial-gradient(circle, rgba(250, 250, 250, 1) 0%, rgba(250, 250, 250, 1) 80%, rgba(190, 190, 190, 1) 100%);
	background-position: top center;
	background-repeat: no-repeat;
}

.sec-padd {
	padding: 100px 0;
	@include respond-to('huge') {
		padding: 80px 0;
	}
	@include respond-to('1100') {
		padding: 50px 0;
	}
	@include respond-to('medium') {
		padding: 40px 0;
	}
	@include respond-to('small') {
		padding: 30px 0;
	}
	@include respond-to('extra-small') {
		padding: 25px 0;
	}
}

.title {
	margin: 0;
	font-size: 30px;
	color: $text;
	font-weight: 700;
	line-height: 1;
	@include respond-to ('small') {
		font-size: 27px;
	}
	@include respond-to ('extra-small') {
		font-size: 25px;
	}
	&_tac {
		text-align: center;
	}
	&_mb {
		margin-bottom: 25px;
		@include respond-to('small') {
			margin-bottom: 20px;
		}
	}
	&_white {
		color: #fff;
	}
}

.text {
	font-size: 18px;
	font-weight: 400;
	color: $text;
	line-height: 1.4;
	@include respond-to('small') {
		font-size: 17px;
	}
	@include respond-to('extra-small') {
		font-size: 16px;
		text-align: justify;
	}
	&_white {
		color: #fff;
	}
}

.hp-about {
	position: relative;
	&__wr {
		max-width: 670px;
		width: 100%;
		padding: 35px 30px;
		border-#{$right}: 2px solid #000;
		@include respond-to('extra-small') {
			padding: side-values(10px 15px 10px 0);
		}
	}
	&__title {}
	&__text {
		@include respond-to('extra-small') {
			text-align: justify;
		}
	}
}

.decor-logo {
	position: absolute;
	top: 0;
	#{$left}: 70px;
	height: 423px;
	width: 696px;
	background-image: url(../images/logo-bg.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include respond-to('large') {
		height: 235px;
		width: 400px;
		#{$left}: 20px;
		background-size: contain;
	}
	@include respond-to('extra-small') {
		#{$left}: 0px;
	}
}

.hp-steps {
	background-color: #fff;
	&__title {}
	&__text {
		text-align: center;
		@include respond-to('extra-small') {
			text-align: justify;
		}
	}
	&__items {
		padding-top: 80px;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-around;
		@include respond-to('larger') {
			padding-top: 40px;
		}
		@include respond-to('small') {
			padding-top: 25px;
		}
	}
	&__item {
		position: relative;
		flex: 1 1 20%;
		display: flex;
		align-self: center;
		min-width: 130px;
		@include respond-to('1100') {
			flex: 1 1 33.33%;
			max-width: 250px;
			margin-bottom: 30px;
		}
	}
}

.step {
	position: relative;
	padding: 5px;
	text-align: center;
	height: 145px;
	&__number {
		pointer-events: none;
		position: absolute;
		left: 0;
		right: 0;
		top: -10px;
		bottom: 0;
		margin: auto;
		z-index: 1;
		font-size: 200px;
		font-weight: 600;
		line-height: 145px;
		color: #eeeeee;
	}
	&__title {
		width: 100%;
		align-self: center;
		position: relative;
		z-index: 2;
		color: #000;
		font-size: 19px;
		font-weight: 700;
		line-height: 1;
		letter-spacing: 0px;
		@include respond-to('small') {
			font-size: 17px;
		}
	}
}

.hp-services {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	&__title {}
	&__text {
		max-width: 630px;
		width: 100%;
		margin: 0 auto;
		text-align: center;
		line-height: 1.2;
		@include respond-to('extra-small') {
			text-align: justify;
		}
	}
	&__items {
		padding-top: 60px;
		font-size: 0;
		text-align: center;
		@include respond-to('large') {
			padding-top: 50px;
		}
		@include respond-to('medium') {
			padding-top: 40px;
		}
		@include respond-to('small') {
			padding-top: 30px;
			max-width: 500px;
			margin: 0 auto;
		}
	}
	&__item {
		display: inline-block;
		vertical-align: middle;
		width: 20%;
		max-width: 250px;
		font-size: initial;
		@include respond-to('1100') {
			width: 33.33%;
			margin-bottom: 25px;
			vertical-align: top;
		}
		@include respond-to('medium') {}
		@include respond-to('small') {
			width: 50%;
		}
		@include respond-to('extra-small') {
			width: 100%;
		}
		&:nth-child(3) {
			& .hp-service-item__icon {
				border: 5px solid #fff;
				border-bottom: none;
				transform: scale(1.2);
				@include respond-to('1100') {
					border: 1px solid #fff;
				}
			}
			& .hp-service-item__title {
				font-size: 30px;
				font-weight: 700;
				@include respond-to('1100') {
					font-size: 20px;
				}
			}
		}
	}
}

.hp-service-item {
	display: block;
	padding: 0 5px;
	@include link_no-hover(#fff);
	&__icon {
		position: relative;
		top: 0;
		width: 100px;
		height: 80px;
		margin: 0 auto 12px;
		border: 1px solid #fff;
		border-bottom: none;
		text-align: center;
		line-height: 80px;
		transition: top 0.2s ease;
	}
	&__title {
		font-size: 20px;
		font-weight: 700;
		color: #fff;
		line-height: 1;
	}
	&:hover {
		& .hp-service-item__icon {
			top: -10px;
		}
		& .hp-service-item__title {}
	}
}

.prod-sec-sliders {
	margin: 0 -20px;
	padding: 40px 0;
	@include respond-to('medium') {
		padding: 20px 0;
		// margin: 0 -10px;
	}
	@include respond-to('small') {
		padding: 10px 0;
	}
}

.prod-sec-slider {
	& .slick-list {
		padding: 25px 0 !important;
	}
	& .slick-dots {
		position: absolute;
		bottom: -60px;
		#{$left}: 0;
		text-align: center;
		@include respond-to('medium') {
			bottom: -45px;
		}
		@include respond-to('small') {
			bottom: -30px;
		}
		& li {
			margin-#{$left}: 0;
			& button {
				height: 5px;
				width: 16px;
				padding: 0;
				&::before {
					opacity: 1;
					content: '';
					background-color: #e7e7e7;
					height: 5px;
					width: 16px;
					@include respond-to('mobile-l') {
						height: 3px;
						width: 10px;
					}
				}
			}
			&.slick-active {
				& button {
					&::before {
						background-color: #000;
					}
				}
			}
		}
	}
	&__item {
		margin: 10px 20px;
		height: 288px;
		transition: transform 0.4s ease;
		@include respond-to('medium') {
			margin: 13px;
			height: 258px;
		}
		@include respond-to('small') {
			height: 250px;
			max-width: 388px;
		}
		@include respond-to('extra-small') {
			margin: 0 50px;
		}
		@include respond-to('mobile-m') {
			margin: 0 40px;
		}
		&.slick-center,
		&.slick-current {
			transform: scale(1.1);
			& .homepage-box-info {
				background-color: rgba($color: $text, $alpha: 0.8);
				padding: 10px;
				@include respond-to('small') {
					padding: 5px;
				}
				&__title {
					color: #fff;
				}
				&__desc {
					display: block;
				}
			}
		}
	}
}

.hp-projects {
	&__title {}
}