.banner-top-content {
	position: relative;
	width: 100%;
	min-height: 450px;
	@include respond-to('medium') {
		min-height: 450px;
	}
	@include respond-to('small') {
		min-height: 400px;
	}
	@include respond-to('extra-small') {
		min-height: 350px;
	}
	@include respond-to('mobile-l') {
		min-height: 300px;
	}
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: #000000, $alpha: 0.5);
	}
}

.banner-top-content__image {
	background-position: center center;
	background-size: cover;
	height: 450px;
	@include respond-to('medium') {
		height: 450px;
	}
	@include respond-to('small') {
		height: 400px;
	}
	@include respond-to('extra-small') {
		height: 350px;
	}
	@include respond-to('mobile-l') {
		height: 300px;
	}
}

.banner-title-block {
	position: absolute;
	z-index: 1;
	top: 64%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 1230px;
	width: 100%;
	padding: 0 15px;
	&__title {
		display: inline-block;
		margin: 0;
		padding-bottom: 25px;
		border-bottom: 3px solid #fff;
		line-height: 1;
		font-size: 40px;
		font-weight: 900;
		color: #fff;
		@include respond-to('medium') {
			font-size: 30px;
			padding-bottom: 10px;
		}
		@include respond-to('small') {
			font-size: 25px;
		}
	}
	&__breadcrumbs {}
}
