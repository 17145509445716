.services {
	&__item {
		border-bottom: 1px solid #d3d3d3;
		&:last-child {
			border-bottom: none;
		}
	}
}

.service-item {
	font-size: 0;
	padding: 50px 0;
	@include respond-to('medium') {
		padding: 30px 0;
	}
	&__photo {
		display: inline-block;
		vertical-align: top;
		font-size: initial;
		border-#{$left}: 2px solid #000;
		padding-#{$left}: 10px;
		width: 20.5%;
		@include respond-to('larger') {
			width: 22%;
		}
		@include respond-to('1100') {
			width: 25%;
		}
		@include respond-to('medium') {
			width: 28%;
		}
		@include respond-to('small') {
			width: 100%;
			border-#{$left}: none;
			margin-bottom: 0px;
			padding-bottom: 0px;
			padding-#{$left}: 0;
			text-align: center;
		}
	}
	&__img {
		position: relative;
		display: block;
		width: 210px;
		height: 200px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-color: #000;
		box-shadow: 0 0 15px 0 rgba($color: #000000, $alpha: 0.2);
		@include respond-to('medium') {
			width: 185px;
			height: 180px;
		}
		@include respond-to('small') {
			margin: 0 auto;
		}
	}
	&__default-img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 95%;
		max-height: 95%;
	}
	&__info {
		width: 79.5%;
		display: inline-block;
		vertical-align: top;
		padding-#{$right}: 40px;
		padding-top: 20px;
		font-size: initial;
		@include respond-to('larger') {
			width: 78%;
		}
		@include respond-to('1100') {
			width: 75%;
			padding-#{$right}: 20px;
		}
		@include respond-to('medium') {
			// padding-#{$right}: 35px;
			width: 72%;
		}
		@include respond-to('small') {
			width: 100%;
			text-align: center;
			padding-#{$right}: 0;
		}
	}
	&__header {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		margin-bottom: 20px;
		@include respond-to('small') {
			justify-content: center;
		}
	}
	&__icon {
		width: 55px;
		display: flex;
		align-self: center;
		& img {
			filter: brightness(0);
			max-width: 37px;
		}
	}
	&__titles {}
	&__title {
		position: relative;
		font-size: 30px;
		font-weight: 900;
		color: #000;
		line-height: 1;
		@include respond-to('small') {
			// display: inline-block;
			font-size: 25px;
			text-align: $right;
		}
		@include respond-to('extra-small') {
			font-size: 22px;
		}
	}
	&__subtitle {
		position: relative;
		font-size: 25px;
		font-weight: 400;
		color: #000;
		line-height: 1;
		@include respond-to('small') {
			display: inline-block;
			font-size: 25px;
			text-align: $right;
		}
		@include respond-to('extra-small') {
			font-size: 22px;
		}
	}
	&__text {
		font-size: 18px;
		font-weight: 400;
		color: #000;
		line-height: 1.2;
		@include respond-to('small') {
			font-size: 17px;
		}
		& p {}
	}
	&__btn {
		margin-top: 45px;
		@include respond-to('medium') {
			margin-top: 25px;
		}
		& a {
			display: inline-block;
			padding-top: 5px;
			border-top: 2px solid $text;
			font-size: 18px;
			font-weight: 900;
			@include link_no-hover(#000);
		}
	}
}