.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	transition: all 0.4s ease;
}


.header-desktop {
	height: 100px;
	background-color: rgba($color: #fff, $alpha: 0.9);
	box-shadow: 0 0 15px 0 rgba($color: #000000, $alpha: 0.3);
	@include respond-to('medium') {
		display: none;
	}
}

.logo {
	float: $left;
	margin-top: 5px;
	& img {
		max-width: 75px;
	}
}  

.phone {
	float: $left; 
	margin-#{$left}: 20px;
	margin-top: 36px;
	& a {
		font-size: 20px;
		font-weight: 600;
		display: block;
		@include link_no-hover($text);
		& i {
			margin-#{$left}: 5px;
		}
	}
}

.main-navi {
	display: inline-block;
	& > ul {
		display: block;
		list-style: none;
		margin-#{$right}: 0px;
		margin-top: 36px;
		margin-bottom: 0;
		padding: 0;
		transition: margin 0.2s ease;
		& > li {
			float: $right;
			margin-#{$left}: 28px;
			position: relative;
			&:last-child {
				margin-#{$left}: 0;
			}
			& > a {
				font-size: 20px;
				font-weight: 600;
				color: $text;
				display: block;
				transition: color 0.2s ease;
				@include link_no-hover($text);
				@include respond-to('large') {
					// font-size: 18px;
				}
				&.active {}
			}
		}
	}
}

.header-mobile {
	display: none;
	background-color: rgba($color: #fff, $alpha: 0.9);
	box-shadow: 0 0 15px 0 rgba($color: #000000, $alpha: 0.3);
	height: 70px;
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 5px;
		float: $left;
		& > img {
			width: 75px;
		}
	}
	&__btn {
		float: $right;
	}
	&__tel {
		display: inline-block;
		margin-top: 17px;
		i {
			color: $text;
			font-size: 32px;
		}
	}
}

.main-header.scrolling {}