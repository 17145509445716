.projects {
	background-color: #fff;
	padding-top: 1px;
	&_white {
		padding-bottom: 50px;
		background-color: #fff;
		@include respond-to('extra-small') {
			padding-bottom: 25px;
		}
	}
	&__item {
		float: $right;
		width: 33.33%;
		height: 450px;
		padding: 1px;
		@include respond-to('huge') {
			height: 400px;
		}
		@include respond-to('larger') {
			height: 350px;
		}
		@include respond-to('larger') {
			height: 300px;
		}
		@include respond-to('small') {
			width: 50%;
			height: 270px;
		}
		@include respond-to('extra-small') {
			width: 100%;
			height: 250px;
		}
		&:nth-child(5n+4),
		&:nth-child(5n+5) {
			width: 50%;
			height: 600px;
			padding: 1px;
			@include respond-to('huge') {
				height: 500px;
			}
			@include respond-to('large') {
				height: 450px;
			}
			@include respond-to('medium') {
				height: 350px;
			}
			@include respond-to('small') {
				height: 270px;
			}
			@include respond-to('extra-small') {
				width: 100%;
				height: 250px;
			}
		}
	}
}