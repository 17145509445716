.homepage-box {
	position: relative;
	display: block;
	height: 100%;
	width: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: 0 0 13px 0px rgba($color: #000000, $alpha: 0.2);
}

.homepage-box-info {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	padding: 15px;
	background-color: rgba($color: #fff, $alpha: 0.8);
	text-align: center;
	@include link_no-hover(#fff);
	@include respond-to('small') {
		// padding: 8px;
	}
	&__title {
		margin-bottom: 8px;
		line-height: 1;
		font-size: 20px;
		font-weight: 700;
		color: #000;
		@include respond-to('medium') {
			font-size: 18px;
			margin-bottom: 5px;
		}
	}
	&__desc {
		display: none;
		max-width: 195px;
		width: 100%;
		margin: 0 auto;
		line-height: 1;
		font-size: 16px;
		font-weight: 400;
		color: #fff;
		@include respond-to('medium') {
			font-size: 15px;
		}
	}
}